export const COMMENTS =
[
    {
        id: 0,
        projectId: 0,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 1,
        projectId: 0,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 2,
        projectId: 0,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 3,
        projectId: 0,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 4,
        projectId: 0,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    },
    {
        id: 5,
        projectId: 1,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 6,
        projectId: 1,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 7,
        projectId: 1,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 8,
        projectId: 1,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 9,
        projectId: 1,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    },
    {
        id: 10,
        projectId: 2,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 11,
        projectId: 2,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 12,
        projectId: 2,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 13,
        projectId: 2,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 14,
        projectId: 2,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    },
    {
        id: 15,
        projectId: 3,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 16,
        projectId: 3,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 17,
        projectId: 3,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 18,
        projectId: 3,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 19,
        projectId: 3,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    },
    {
        id: 20,
        projectId: 4,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 21,
        projectId: 4,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 22,
        projectId: 4,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 23,
        projectId: 4,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 24,
        projectId: 4,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    },
    {
        id: 25,
        projectId: 5,
        rating: 5,
        comment: "Amaizing project!",
        author: "Francis",
        date: "2021-10-16T17:57:28.556094Z"
    },
    {
        id: 16,
        projectId: 3,
        rating: 4,
        comment: "True display of skills man :)",
        author: "Paul Vites",
        date: "2021-11-05T17:57:28.556094Z"
    },
    {
        id: 26,
        projectId: 5,
        rating: 3,
        comment: "Can we partner for a project? :)",
        author: "Joy C",
        date: "2022-01-13T17:57:28.556094Z"
    },
    {
        id: 27,
        projectId: 5,
        rating: 4,
        comment: "Ultimate, Reaching for the stars!",
        author: "Ian Braines",
        date: "2022-02-14T17:57:28.556094Z"
    },
    {
        id: 28,
        projectId: 5,
        rating: 2,
        comment: "It's amaizing how you manage to learn all these!",
        author: "Jane Bliss",
        date: "2022-03-02T17:57:28.556094Z"
    }
];
